import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/Image'
import { v4 } from 'uuid'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

const futures = [
  {
    id: v4(),
    src: './images/plat.png',
    title: 'Платежеспособность и финансовая устойчивость',
    desc: 'Можно ли работать в долг или платить авансы контрагенту? Сколько у него долгов, сколько можно дать в кредит? Насколько он вообщенадежен, платежеспособен, и какова вероятность банкротства?',
  },
  {
    id: v4(),
    src: './images/inbazar.png',
    title: 'Положение на рынке',
    desc: 'Рейтинги компаний, владельцев, управляющих по регионам и отраслям. Кто они, самые крупные владельцы и самые влиятельные директора? Какое положение занимают компании на рынке: кто из них меньше, а кто крупнее?',
  },
  {
    id: v4(),
    src: './images/who.png',
    title: 'Кто чем владеет и управляет',
    desc: 'Чем владеет или управляет ваш знакомый или просто интересный вам человек? Какие компании связаны с ним косвенно, и каково их финансовое положение? Не уводят ли ваши сотрудники бизнес через теневые структуры?',
  },
  {
    id: v4(),
    src: './images/look.png',
    title: 'Конкурентная разведка',
    desc: 'Кому поставляют товар или оказывают услуги ваши конкуренты? С кем придется столкнуться на тендере, и кто наиболее вероятный победитель.',
  },
  {
    id: v4(),
    src: './images/watch.png',
    title: 'Надежность партнеров',
    desc: 'Не являются ли их компании однодневками? Не «сливают» ли они периодически свои компании в «помойку» вместе с накопившимися по ним долгами?',
  },
  {
    id: v4(),
    src: './images/dog.png',
    title: 'Поиск новых клиентов',
    desc: 'Получить базу потенциальных покупателей с актуальными контактными данными, отранжированную по степени привлекательности.',
  },
  {
    id: v4(),
    src: './images/man.png',
    title: 'Наблюдение за компаниями',
    desc: 'Подписка на изменения по конкретной компании даст своевременные сведения о том, когда к компании будут поданы судебные иски или изменится финансовое положение, руководство, состав учредителей или что-то другое.',
  },
  {
    id: v4(),
    src: './images/eggs.png',
    title: 'Инвестиционная привлекательность',
    desc: 'Как изменится прибыль компании, если привлечь дополнительные инвестиции? Какова эффективность вложения средств в нее? Насколько выше или ниже рентабельность и другие показатели относительно средних по отрасли и региону?',
  },
  {
    id: v4(),
    src: './images/mony.png',
    title: 'Стоимость бизнеса',
    desc: 'Сколько стоит своя или любая интересующая вас компания? Из чего складывается эта стоимость, как она менялась за последнее время? Как акции компании торгуются на бирже? Сравнение стоимости своей компании с конкурентами.',
  },
]

export const AboutCompany = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mt-5">
          <Col className="d-flex flex-column">
            <h5>Все о компаниях и владельцах</h5>
            <h6>СБИС позволяет решать самые разнообразные задачи</h6>
          </Col>
        </Row>
        <Row>
          {futures.map((future) => (
            <>
              <Col className="d-flex flex-column " md={12} lg={4}>
                <Image src={future.src} className="p-3" />
                <h6 className="p-3">{future.title}</h6>
                <p className="p-3">{future.desc}</p>
              </Col>
            </>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  )
}
