import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '25.000,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '37.600,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '43.100,00',
  },
]

const data = {
  img: './images/st72-02-1.png',
  title: 'Эвотор 7.2 Смарт-терминал',
  desc: 'Одна из самых доступных по цене моделей смарт-терминалов. Подойдёт магазинам у дома, кафе, сервисам по ремонту, химчисткам, прокату, ломбардам.',
}

export const St72 = () => {
  return (
    <>
      <ItemProd data={data} futures={futures} />
    </>
  )
}
