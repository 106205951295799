import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/esm/Button'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

export const ListProd = ( { data, futures } ) =>
{
    return (
        <>
            <Header />
            <Navigation />
            <Container>
                <Row className="mt-5">
                    <Col>
                        <h4>{ data.title }</h4>
                        <p className="fs-6">
                            { data.desc }
                        </p>
                    </Col>
                </Row>
                <Row className="mt-5 g-5">
                    { futures.map( ( future ) => (
                        <>
                            <Col lg={ 4 } md={ 12 }>
                                <Card>
                                    <Card.Img variant="top" src={ future.src } />
                                    <Card.Body>
                                        <Card.Title>{ future.title }</Card.Title>
                                        <Card.Text>{ future.desc }</Card.Text>
                                    </Card.Body>
                                    <Button variant="light" type="null" href={ future.url }>
                                        Подробнее...
                                    </Button>
                                </Card>
                            </Col>
                        </>
                    ) ) }
                </Row>
            </Container>
            <Footer />
        </>
    )
}
