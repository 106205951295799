import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import { TelephoneFill } from 'react-bootstrap-icons'

export const Header = () =>
{
  return (
    <>
      <Navbar>
        <Container>
          <Navbar.Brand href="/">
            <Image src="./images/logo.png" className="logoimg" />
          </Navbar.Brand>
          <div>
            <TelephoneFill className="phonepic nav-custom-item" />
            <a
              href="tel: +74954813966"
              className="text-decoration-none phonepic nav-custom-item"
            >
              +7(495)481-39-66
            </a>
          </div>
        </Container>
      </Navbar>
    </>
  )
}
