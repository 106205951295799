import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Header } from './header'
import { Navigation } from './navigation'
import { Footer } from './footer'
import { ColoredLine } from './coloredline'

export const ItemProd = ( { data, futures } ) =>
{
    return (
        <>
            <Header />
            <Navigation />
            <Container>
                <Row className="mb-5">
                    <Col className="d-flex justify-content-center align-items-start" lg={ 4 } mg={ 12 }>
                        <Image
                            className="me-0"
                            src={ data.img }
                            width='75%'
                        />
                    </Col>
                    <Col className="d-flex flex-column justify-content-start align-items-start" lg={ 8 } mg={ 12 }>
                        <h4>{ data.title }</h4>
                        <p>
                            { data.desc }
                        </p>
                    </Col>

                    <Col className="justify-content-center align-items-start" lg={ 4 } mg={ 12 }></Col>
                    <Col className="d-flex flex-column justify-content-start align-items-start" lg={ 8 } mg={ 12 }>
                        <Row>
                            { futures.map( ( future ) => (
                                <>

                                    <h6>{ future.title }
                                        :<i><b>   { future.desc } ₽</b></i></h6>

                                    <ColoredLine color="#30408c" />
                                </>
                            ) ) }
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
