import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '70000,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '81000,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '86000,00',
  },
]

const data = {
  img: './images/kassy-viki-tower.jpg',
  title: 'Онлайн-касса Viki Tower',
  desc: 'Промышленное кассовое решение в металлическом корпусе. Сенсорный моноблок, дисплей покупателя и принтер с печатью QR-кодов защищены алюминиевой обшивкой. На чеках 80 мм полностью помещаются названия товаров, сообщения о скидках и акциях. Viki Tower существует в двух размерах: с экраном 10″ или 15″.'
}

export const VikiTower = () =>
{
  return (
    <>
      <ItemProd data={ data } futures={ futures } />
    </>
  )
}

