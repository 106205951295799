import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

export const Otchet = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mb-5 mt-5">
          <Col
            className="d-flex flex-column justify-content-center align-items-start"
            lg={6}
            md={12}
          >
            <h5>Отчетность через интернет</h5>
            <p>
              Подготовка, проверка, анализ и сдача отчетности во все госорганы,
              сверка расчетов с бюджетом, переписка с инспекторами
            </p>
          </Col>
          <Col
            className="d-flex flex-column justify-content-start align-items-end"
            lg={6}
            md={12}
          >
            <Button
              variant="outline-info"
              href="https://sbis.ru/ereport"
              target="_blank"
            >
              Официальный сайт СБИС - https://sbis.ru/ereport
            </Button>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column" md={12} lg={6}>
            <h5>Единое окно для сдачи отчетности</h5>
          </Col>
          <Col className="d-flex justify-content-center" md={12} lg={6}>
            <Row className="d-flex flex-column">
              <Col className="d-inline-flex mb-4">
                <Image src="./images/fns-logo.png" className="me-4" />
                <Image src="./images/pfr-logo.png" className="me-4" />
                <Image src="./images/fss-logo.png" className="me-4" />
                <Image src="./images/rosstat-logo.png" className="me-4" />
              </Col>
              <Col className="d-inline-flex">
                <Image src="./images/rar-logo.png" className="me-4" />
                <Image src="./images/rpn-logo.png" className="me-4" />
                <Image src="./images/fms-logo.png" className="me-4" />
              </Col>
            </Row>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center"
            md={12}
            lg={6}
          >
            <p>
              СБИС — система подготовки, проверки и сдачи отчетности через
              Интернет во все государственные органы. В системе реализованы все
              возможные отчеты. СБИС заботится об их актуальности, обновляя
              формы отчетов сразу после их утверждения.
            </p>
            <p>
              СБИС работает 365 дней в году, 24 часа в сутки. Даже если вы
              отправите отчет за 5 минут до полуночи, будет считаться, что он
              представлен в срок.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Для бизнеса любых размеров</h5>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column " lg={4} md={12}>
            <Image className="mb-3" src="./images/small-buisness.png" />
            <p>
              Среднему и малому бизнесу:
              <ul>
                <li>Отчеты «как на бумаге»</li>
                <li>Помощь по заполнению отчетов</li>
                <li>Календарь со сроками сдачи</li>
                <li>Робот для сдачи нулевки</li>
                <li>
                  Понятный любому фин. анализ и советы по оптимизации налогов
                </li>
              </ul>
            </p>
          </Col>
          <Col className="d-flex flex-column" lg={4} md={12}>
            <Image className="mb-3" src="./images/large-buisness.png" />
            <p>
              Крупным компаниям:
              <ul>
                <li>Легкая сдача отчетов за филиалы</li>
                <li>Работа с большими отчетами</li>
                <li>Удобный ответ на истребования</li>
                <li>
                  Сверка по налогам за один клик по всем филиалам и «дочкам»
                </li>
                <li>Гибкая настройка прав доступа</li>
              </ul>
            </p>
          </Col>
          <Col className="d-flex flex-column" lg={4} md={12}>
            <Image className="mb-3" src="./images/other-buisness.png" />
            <p>
              Уполномоченной бухгалтерии:
              <ul>
                <li>Отправка любого количества отчетов за один клик</li>
                <li>Согласование и переписка по отчету с заказчиком</li>
                <li>Управление доверенностями</li>
                <li>Механизм автозаполнения отчетов</li>
              </ul>
            </p>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col
            className="d-flex flex-column justify-content-center"
            lg={6}
            md={12}
          >
            <Image src="./images/calendar.png" width="75%" />
          </Col>
          <Col
            className="d-flex flex-column justify-content-center"
            lg={6}
            md={12}
          >
            <h5>Что, когда и куда сдавать?</h5>
            <p>
              СБИС сформирует для вас персональный календарь бухгалтера для
              отчетности и платежей ваших компаний. В нем будут указаны только
              те отчеты и сроки их сдачи, которые актуальны именно для вас.
              Ничего лишнего!
            </p>
            <p>
              Когда подойдет срок сдачи отчета, СБИС обязательно напомнит,
              отправив вам sms, e-mail или уведомление в мобильном приложении.
              Ну и конечно, СБИС известит вас об успешной сдаче отчета или о
              направлении в ваш адрес требований из госорганов.
            </p>
            <p>
              Для тех, у кого несколько филиалов или компаний, СБИС сформирует
              наглядные таблицы для контроля подготовки и сдачи отчетности,
              распределения работ между различными бухгалтерами.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column " md={12} lg={6}>
            <h5>Помощник по заполнению отчетов</h5>
            <p>
              При заполнении отчета удобно иметь рядом консультанта, который
              отвечал бы на все ваши вопросы: а что в этой строке? откуда эта
              цифра берется? Просто наведите мышкой на ячейку, вызывающую
              затруднения, и СБИС все объяснит. Причем, если надо, тут же будут
              развернутые комментарии опытных аудиторов.
            </p>
            <p>
              А еще СБИС поможет побороть рутину, автоматически заполнив те
              строки, которые переносятся или которые можно рассчитать из ранее
              созданных отчетов.
            </p>
          </Col>
          <Col className="d-flex justify-content-center" lg={6} md={12}>
            <Image src="./images/balanc.png" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex justify-content-center" lg={6} md={12}>
            <Image src="./images/balanc_1.png" />
          </Col>
          <Col className="d-flex flex-column " lg={6} md={12}>
            <h5>Проверка отчетов по алгоритмам госорганов</h5>
            <p>
              Во-первых, СБИС регулярно проверяет актуальность отчетов,
              загружаемых из сторонних программ. Если подготовленный вами в 1С
              отчет устарел, СБИС автоматически преобразует его в актуальный
              формат.
            </p>
            <p>
              Во-вторых, СБИС проверяет все цифры как внутри одного отчета, так
              и между разными формами по тем же алгоритмам, что и госорганы. В
              СБИС заложены более 2800 (!) контрольных соотношений. Будьте
              уверены, СБИС найдет все возможные несоответствия и ошибки в ваших
              отчетах.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column  " lg={6} md={12}>
            <h5>Сверка НДС</h5>
            <p>
              ФНС выполняет тотальную сверку данных о счетах-фактурах по всем
              контрагентам. Расхождения приводят к доначислению НДС и штрафам.
              СБИС снижает эти риски. Перед сдачей декларации по НДС он проверит
              по каждому счету-фактуре:
            </p>
            <ul>
              <li>корректность реквизитов контрагента;</li>
              <li>«действовал» ли он по данным ЕГРЮЛ/ЕГРИП на дату сделки;</li>
              <li>
                сходятся ли суммы и реквизиты счета-фактуры у вас и вашего
                контрагента.
              </li>
            </ul>
            <p>
              В итоге СБИС покажет, есть ли риск доначисления НДС, объяснив суть
              претензий по каждой сделке.
            </p>
          </Col>
          <Col className="d-flex justify-content-center" lg={6} md={12}>
            <Image src="./images/balanc_2.png" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex  justify-content-center" lg={6} md={12}>
            <Image src="./images/balanc_3.png" />
          </Col>
          <Col className="d-flex flex-column " lg={6} md={12}>
            <h5>Сверка расчетов с бюджетом</h5>
            <p>
              Со СБИС вы сверите расчеты с бюджетом через Интернет за пару
              часов, не покидая офиса. Причем СБИС не только быстрее доставит до
              вас справки из госорганов, но и преобразует их громоздкую форму в
              наглядный акт сверки с четко выделенными итогами и понятной
              расшифровкой.
            </p>
            <p>
              Используя СБИС, вы сможете оперативно контролировать зачисление
              уплаченных налогов, не допустите недоимок/переплат и будете
              уверены в отсутствии долгов перед бюджетом при подаче документов
              на кредит или участие в тендере.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="mb-5">
            <h5>СБИС больше, чем отчетность</h5>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column " lg={6} md={12}>
            <Image src="./images/graph-pic.png" width="50" />
            <h5>Оптимизируйте свои налоги</h5>
            <p>
              СБИС сравнит вашу налоговую нагрузку со средней по отрасли, оценив
              эффективность налоговой политики. Проанализирует динамику каждого
              налога и посоветует сменить режим налогообложения, если это
              возможно и выгодно
            </p>
          </Col>
          <Col className="d-flex flex-column " lg={6} md={12}>
            <Image src="./images/search-pic.png" width="50" />
            <h5>Узнайте заранее о проверках</h5>
            <p>
              СБИС отслеживает данные Единого реестра о плановых и внеплановых
              проверках бизнеса контролирующими органами. Вы заранее узнаете о
              предстоящем визите инспекторов, подготовитесь и минимизируете
              штрафы.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Image src="./images/sbis-rotating.png" />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column " md={12} lg={6}>
            <Image src="./images/ves-pic.png" width="50" />
            <h5>Анализируйте финансовое состояние</h5>
            <p>
              СБИС рассчитает рентабельность, сравнив ее со средними значениями
              по отрасли, оценит стоимость бизнеса и структуру активов,
              зависимость от кредиторов и многое другое. Важно, что СБИС сделает
              это на доступном языке. Вы получите экономическую и финансовую
              оценку работы компании без расходов на консультанта, да и правду
              узнаете только вы.
            </p>
          </Col>
          <Col className="d-flex flex-column " md={12} lg={6}>
            <Image src="./images/oko-pic.png" width="50" />
            <h5>Взгляние на бизнес глазами инспектора</h5>
            <p className="align-items-end">
              Чтобы не попасть в зону "особого внимания" госорганов, надо
              выполнять критерии "добросовестности". Для ФНС таких критериев 40,
              для ФСС – 12. СБИС рассчитает их за минуту, на основе данных всех
              ваших отчетов. Сравнит ваши значения со среднеотраслевыми по
              налоговой нагрузке, рентабельности, средней зарплате. Оценит
              вероятность проверки и даст рекомендации по снижению рисков.
            </p>
          </Col>
        </Row>
        <Row className="mb-5 mt-5">
          <Col className="d-flex  justify-content-center" md={12} lg={6}>
            <Image src="./images/robot.png" />
          </Col>
          <Col className="d-flex flex-column " md={12} lg={6}>
            <h5>Робот для сдачи нулевки</h5>
            <p>
              Компания не ведет деятельности, а сдавать отчетность, хоть и
              нулевую, все равно приходится. Укажите это в СБИС. Формирование и
              отправка отчетности будет проходить автоматически, с вас лишь
              молчаливое согласие.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column " md={12} lg={6}>
            <h5>Переписка с госорганами</h5>
            <p>
              В СБИС, кроме отчетов, вы можете отправить инспектору любой
              документ: пояснительную записку, вопрос по новой норме закона,
              скан лицензии и даже открытку на профессиональный праздник. Каждый
              документ СБИС заверит электронной подписью и зашифрует, обеспечив
              юридическую значимость и конфиденциальность.
            </p>
            <p>
              Точно так же СБИС доставит и вам документы и запросы со стороны
              госорганов. Даже новости с доски объявлений в налоговой или
              пенсионном фонде вы увидите в СБИС на своем рабочем столе.
            </p>
          </Col>
          <Col className="d-flex justify-content-center" md={12} lg={6}>
            <Image src="./images/writer.png" />
          </Col>
        </Row>
        <Row className="mb-5 mt-5">
          <Col className="d-flex justify-content-center" md={12} lg={6}>
            <Image src="./images/secretar.png" />
          </Col>
          <Col className="d-flex flex-column " md={12} lg={6}>
            <h5>Предоставление отчетности в банк для получения кредита</h5>
            <p>
              Для получения кредита в банк необходимо предоставить налоговую и
              бухгалтерскую отчетность, предварительно заверенную в инспекции.
            </p>
            <p>
              Если отчетность у вас в СБИС, то все гораздо проще – отправляйте
              отчеты в банк прямо из системы через интернет, если банк подключен
              к СБИС, или по email. Банк получит отчеты, заверенные подписью
              налоговой.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
