import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '29000,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '40000,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '45000,00',
  },
]

const data = {
  img: './images/fiskalnyj-registrator-80-plus.jpg',
  title: 'Онлайн-ККТ Viki Print 80 Plus',
  desc: ' Ассортимент вашего магазина может поспорить с супермаркетом, а длинные очереди — обычное явление? Вам нужен онлайн-ККТ, который справится с любыми нагрузками и сам отрезает чеки. Viki Print 80 Plus печатает чеки на 80 мм бумаге. На таких чеках умещаются даже самые длинные названия товаров. Кроме того, на чеке остаётся место для логотипа, QR-кода или другой информации.'
}

export const Viki80Plus = () =>
{
  return (
    <>
      <ItemProd data={ data } futures={ futures } />
    </>
  )
}

