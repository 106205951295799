import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Partners } from '../components/partners'
import { Footer } from '../components/footer'
import { v4 } from 'uuid'
import { ColoredLine } from '../components/coloredline'

const futures = [
  {
    id: v4(),
    src: './images/sbis.png',
    title: 'Сеть деловых коммуникаций СБИС',
    desc: 'Компания Компьютерные Системы является официальным представителем компании Тензор в Московском регионе г. Руза. Подключим к системе электронного документооборота СБИС. Поможем сдать отчет в налоговую, пенсионный фонд и другие контролирующие органы в электронном виде. Проконсультируем по работе с электронными подписями.',
    url: '/sbis',
  },
  {
    id: v4(),
    src: './images/54fz.png',
    title: 'Онлайн-кассы',
    desc: 'Компания Компьютерные системы является официальным партнером и представителем компании Дримкас и Эвотор в Московской области г. Руза. Дримкас - производитель оборудования для автоматизации малой розничной торговли: кассовые терминалы с программным обеспечением, фискальные регистраторы, видеоконтроль кассиров. Эвотор - производитель программно-аппаратный комплекс обеспечивающего интеллектуальную и гибко настраиваемую автоматизацию торговой точки. Продадим кассу нового образца, которая работает в соответствии с 54-фз. Доставим и настроим онлайн кассу Дримкас или Эвотор . Продадим и заменим фискальный накопитель ФН-1.',
    url: '/kkt',
  },
  // {
  //   id: v4(),
  //   src: './images/logoCS-shop.png',
  //   title: 'Интернет-магазин программного обеспечения.',
  //   desc: 'Компания Компьютерные Системы расположена в Московской области г. Руза. Тем ни менее, благодаря электронной дистрибуции мы можем доставить программное обеспечение в любую точку земного шара, где есть интернет. В нашем интернет-супермаркете Вы найдете программное обеспечение на любой вкус. Использование электронных каналов позволяет доставлять программное обеспечение в течении 5-10 минут с момента получения оплаты. Поставим и настроим компьютерную технику и программное обеспечение.',
  //   url: 'http://shop.stmltd.ru',
  // },
]

export const Home = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        {futures.map((future) => (
          <>
            <Row className="mb-5">
              <Col
                className="d-flex justify-content-center align-items-start mb-2"
                lg={4}
                md={12}
                xs={12}
              >
                <Image src={future.src} width="300" className="" />
              </Col>
              <Col
                className="d-flex flex-column justify-content-start align-items-start col-8 mt-2"
                lg={8}
                md={12}
                xs={12}
              >
                <h5>{future.title}</h5>
                <p>{future.desc}</p>
                <Button variant="white" href={future.url}>
                  Подробнее...
                </Button>
              </Col>
            </Row>
            <ColoredLine color="#30408c" />
          </>
        ))}
      </Container>
      <Partners />
      <Footer />
    </>
  )
}
