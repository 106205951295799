import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '53000,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '69000,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '86000,00',
  },
]

const data = {
  img: './images/vikimini.jpg',
  title: 'Онлайн-касса Viki Mini',
  desc: ' Полноценное рабочее место кассира с восьмидюймовым экраном. В компактный корпус встроено устройство для печати чеков, модуль Wi-Fi для подключения к беспроводной сети и считыватель магнитных карт. С кассы можно отправлять электронные чеки в ОФД и покупателю, подтверждать закупки алкоголя и отправлять данные вЕГАИС.'
}

export const VikiMini = () =>
{
  return (
    <>
      <ItemProd data={ data } futures={ futures } />
    </>
  )
}

