import React from 'react'
import { v4 } from 'uuid'
import { ListProd } from '../components/listprod'

const futures = [
  {
    id: v4(),
    src: './images/kassa-f-main.png',
    url: '/kassa-f',
    title: 'Онлайн-Касса Ф',
    desc: '9900.00-28000.00',
    width: '300',
  },
  {
    id: v4(),
    src: './images/dreamkas-start.png',
    url: '/dreamkas-start',
    title: 'Онлайн-касса Дримкас Старт',
    desc: '',
    width: '300',
  },
  {
    id: v4(),
    src: './images/kassy-viki-tower.jpg',
    url: '/vikitower',
    title: 'Онлайн-касса Viki Tower',
    desc: '70000.00 - 86000.00',
    width: '300',
  },
  {
    id: v4(),
    src: './images/vikimini.jpg',
    url: '/vikimini',
    title: 'Онлайн-касса Viki Mini',
    desc: '53000.00 - 69000.00',
    width: '300',
  },
  {
    id: v4(),
    src: './images/fiskalnyj-registrator-80-plus.jpg',
    url: '/vikiprint80plus',
    title: 'Онлайн-ККТ Viki Print 80 Plus',
    desc: '29000.00 - 45000.00',
    width: '250',
  },
  {
    id: v4(),
    src: './images/fiskalnyj-registrator-57-plus.jpg',
    url: '/vikiprint57plus',
    title: 'Онлайн-ККТ Viki Print 57 Plus',
    desc: '23000.00 - 39000.00',
    width: '250',
  },
  {
    id: v4(),
    src: './images/fiskalnyj-registrator-57.jpg',
    url: '/vikiprint57',
    title: 'Онлайн-ККТ Viki Print 57',
    desc: '22750.00 - 38750.00',
    width: '250',
  },
]

const data = {
  title: 'Кассы Дримкас',
  desc: 'Кассы и программное обеспечение для предпринимателей.',
}

export const Dreamkas = () => {
  return (
    <>
      <ListProd data={data} futures={futures} />
    </>
  )
}
