import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { ColoredLine } from '../components/coloredline'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { v4 } from 'uuid'
import { Footer } from '../components/footer'

const futures = [
  {
    id: v4(),
    src: './images/evotor-logo.png',
    title: 'Эвотор',
    desc: 'Онлайн-касса, которая настроится под ваш бизнес',
    url: '/evotor',
  },
  {
    id: v4(),
    src: './images/dreamkas-logo.png',
    title: 'Дримкас',
    desc: 'Мы знаем, как сделать ваш бизнес успешнее.',
    url: '/dreamkas',
  },
  {
    id: v4(),
    src: './images/fn.webp',
    title: 'Фискальные накопители',
    desc: 'Фискальный накопитель необходимо менять каждые 13 – 36 месяцев в зависимости от налогового режима и особенности применения.',
    url: '/fn',
  },
]

export const Kkt = () =>
{
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        { futures.map( ( future ) => (
          <>
            <Row className="mb-5">
              <Col className="d-flex justify-content-center align-items-start col-4">
                <Image className="me-0 imgpic" src={ future.src } />
              </Col>
              <Col className="d-flex flex-column justify-content-start align-items-start col-8">
                <h5>{ future.title }</h5>
                <p>{ future.desc }</p>
                <Button variant="white" href={ future.url }>
                  Подробнее...
                </Button>
              </Col>
            </Row>
            <ColoredLine color="#30408c" />
          </>
        ) ) }
        <Row>
          <Col>
            <h4>54-ФЗ о применении контрольно-кассовой техники</h4>
            <p className="fs-5">
              С 2017 года розничные магазины напрямую отчитываются перед ФНС.
              Для этого в магазинах устанавливают новые ККТ с подключением к
              интернету: сразу после продажи касса отправляет электронный чек на
              сервер оператора фискальных данных. ОФД обрабатывает, хранит и
              передает информацию в налоговую службу.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

