import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Contacts } from './pages/Contacts'
import { Kkt } from './pages/Kkt'
import { Sbis } from './pages/Sbis'
import { NotFound } from './pages/NotFound'
import { Edo } from './pages/Edo'
import { Otchet } from './pages/Otchet'
import { AboutCompany } from './pages/AboutCompany'
import { Mercury } from './pages/Mercury'
import { Evotor } from './pages/Evotor'
import { Dreamkas } from './pages/Dreamkas'
import { Kassaf } from './pages/Kassa-f'
import { KassaStart } from './pages/Kassa-start'
import { St5 } from './pages/St5'
import { St72 } from './pages/St72'
import { St73 } from './pages/St73'
import { VikiTower } from './pages/VikiTower'
import { Fn } from './pages/Fn'
import { VikiMini } from './pages/VikiMini'
import { Viki80Plus } from './pages/Viki80Plus'
import { Viki57Plus } from './pages/Viki57Plus'
import { Viki57 } from './pages/Viki57'

export const useRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/sbis" element={<Sbis />} />
      <Route path="/edo" element={<Edo />} />
      <Route path="/otchet" element={<Otchet />} />
      <Route path="/aboutcompany" element={<AboutCompany />} />
      <Route path="/mercury" element={<Mercury />} />
      <Route path="/kkt" element={<Kkt />} />
      <Route path="/evotor" element={<Evotor />} />
      <Route path="/dreamkas" element={<Dreamkas />} />
      <Route path="/fn" element={<Fn />} />
      <Route path="/st5" element={<St5 />} />
      <Route path="/st72" element={<St72 />} />
      <Route path="/st73" element={<St73 />} />
      <Route path="/kassa-f" element={<Kassaf />} />
      <Route path="/dreamkas-start" element={<KassaStart />} />
      <Route path="/vikitower" element={<VikiTower />} />
      <Route path="/vikimini" element={<VikiMini />} />
      <Route path="/vikiprint80plus" element={<Viki80Plus />} />
      <Route path="/vikiprint57plus" element={<Viki57Plus />} />
      <Route path="/vikiprint57" element={<Viki57 />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
