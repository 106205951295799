import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/Image'
import { ColoredLine } from '../components/coloredline'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

export const Mercury = () =>
{
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mt-5">
          <Col className="d-flex flex-column col-12">
            <h5>СБИС Меркурий</h5>
            <p>
              Производители, дистрибьюторы и продавцы мяса, яиц, молока, меда,
              других поднадзорных товаров, с 1 июля 2018 года обязаны работать с
              электронными ветеринарными сопроводительными документами (ВСД) в
              ГИС «Меркурий». Подключиться к системе должны все компании,
              поднадзорные Госветконтролю: розничные магазины, оптовые базы,
              молочные заводы и мясокомбинаты, птицефабрики и производители
              морепродуктов, фермы, племенные хозяйств, а также общепит,
              торговые сети и логистические центры.
            </p>
          </Col>
          <ColoredLine color="#30408c" />
          <Col className="d-flex flex-column col-12">
            <h5>Схема работы ГИС Меркурий</h5>
            <Image src="./images/merc_schm.png" width="75%" />
            <p>
              <ol>
                <li>
                  Производитель (ферма, племенное хозяйство) при продаже и
                  выпуске товара обязан создать ВСД и присвоить ей номер в ГИС
                  Меркурий. Во время отгрузки распечатывает его и передает
                  вместе с товаром.
                </li>
                <li>
                  Дистрибьютор, получив товар, сравнивает фактическое количество
                  с ВСД. Гасит ВСД — принимает или отклоняет. Для дальнейшей
                  перепродажи товара животного происхождения также для каждой
                  позиции создает ВСД в ГИС Меркурий. Распечатывает краткую
                  форму ВСД и передает вместе с товаром.
                </li>
                <li>
                  В момент приемки товаровед сравнивает фактическое количество
                  поступившей продукции с ВСД и гасит их.
                </li>
              </ol>
            </p>
          </Col>
          <ColoredLine color="#30408c" />
          <Col className="d-flex flex-column col-12">
            <h5>СБИС Меркурий для розничных магазинов и заведений общепита</h5>
            <Image src="./images/merc_schm_2.png" width="75%" />
            <p>
              Покупатель (розничный магазин или заведение общепита), получив
              товар от поставщика, в течение одного рабочего дня.
            </p>
            <p>
              <ol>
                <li>
                  Получает ВСД из ГИС Меркурий в СБИС. В результате формируется
                  документ поступления.
                </li>
                <li>Сверяет фактически поступившую продукцию в ВСД.</li>
                <li>Гасит ВСД.</li>
              </ol>
            </p>
            <p>
              В ГИС Меркурий не фиксируются розничные продажи, поэтому для
              выравнивания остатков периодически выполняется инвентаризация
              (пока только на портале «Меркурий»).
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

