import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Partners } from '../components/partners'
import { Footer } from '../components/footer'
import { PatchQuestionFill } from 'react-bootstrap-icons'

export const NotFound = () =>
{
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mb-5 mt-5"></Row>
        <Row className="mb-5 mt-5"></Row>
        <Row className="mb-5 mt-5">
          <Col className="d-flex justify-content-center align-items-start col-4">
            <PatchQuestionFill
              color="#30408c"
              size={ 128 }
              className="ms-5 mb-4"
            />
          </Col>
          <Col className=" d-flex flex-column justify-content-start align-items-start col-8">
            <h1>404 PAGE NOT FOUND</h1>
            <p>We looked everywhere for this page.</p>
            <p>Are you sure the website URL is correct?</p>
            <p>Get on touch with the site owner.</p>
          </Col>
          <Button variant="link" href="/">
            Go Back Home
          </Button>
        </Row>
        <Row className="mb-5 mt-5"></Row>
        <Row className="mb-5 mt-5"></Row>
      </Container>
      <Partners />
      <Footer />
    </>
  )
}
