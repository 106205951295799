import React from 'react'
import { v4 } from 'uuid'
import { ListProd } from '../components/listprod'

const futures = [
  {
    id: v4(),
    src: './images/st5line-02.png',
    url: '/st5',
    title: 'Эвотор 5ST',
    desc: '',
  },
  {
    id: v4(),
    src: './images/st72-02-1.png',
    url: '/st72',
    title: 'Эвотор 7.2 Смарт-терминал',
    desc: '',
  },
  {
    id: v4(),
    src: './images/st73-02-1.png',
    url: '/st73',
    title: 'Эвотор 7.3 Смарт-терминал',
    desc: '',
  },
]

const data = {
  title: 'Смарт терминалы',
  desc: 'Это полноценное кассовое решение: компьютер и фискальный регистратор в одном корпусе.Кассовое ПО — предустановлено.Есть компактные устройства с экранами 5-8 дюймов, они занимают мало места, стильно смотрятся на прилавке.Для разъездной торговли есть модели с аккумулятором.Терминалы помощнее с мониторами 10-15 дюймов рассчитаны на большой поток покупателей.Для большего удобства такой терминал можно закрепить на столе.'
}

export const Evotor = () =>
{
  return (
    <>
      <ListProd data={ data } futures={ futures } />
    </>
  )
}
