import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const Footer = () => {
  return (
    <>
      <Container>
        <Row className="mt-5">
          <Col className="d-flex flex-column align-items-end">
            <div>Московская обл., г.Руза, ул.Федеративная, д.7а, офис 418</div>
            <div>
              <a href="mailto: info@stmltd.ru" className="text-decoration-none">
                info@stmltd.ru
              </a>
            </div>
            <div>
              &copy; 2016-{new Date().getFullYear()} Компьютерные Системы
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
