import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GeoAltFill } from 'react-bootstrap-icons'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Partners } from '../components/partners'
import { Footer } from '../components/footer'

export const Contacts = () =>
{
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mt-5 mb-5">
          <Col className="mt-5" lg={ 6 } md={ 12 }>
            <GeoAltFill color="#30408c" size={ 48 } className="ms-5 mb-4" />

            <h3>Контакты:</h3>
            <h6>
              ОГРН: 1165075050690 <br />
              ИНН: 5075028838 <br />
              Адрес: 143103, г. Руза, ул.Федеративная, д.7а, офис 418 <br />
              Бизнес центр "Руза Хутор" <br />
              +7(495)481-39-66 <br />
              info@stmltd.ru
            </h6>
          </Col>
          <Col lg={ 6 } md={ 12 }>
            <iframe
              title="Мы на Яндекс картах!"
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A6616ce2ef120e9faf29f35c59b51fb79b0707f4f4fa97472b392fea267e05289&amp;source=constructor"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </Col>
        </Row>
      </Container>
      <Partners />
      <Footer />
    </>
  )
}