import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '23000,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '34000,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '39000,00',
  },
]

const data = {
  img: './images/fiskalnyj-registrator-57-plus.jpg',
  title: 'Онлайн-ККТ Viki Print 57 Plus',
  desc: 'Универсальный онлайн-ККТ, который прослужит вам 7 лет. Он быстро печатает чеки, моментально получает данные из кассовой программы и бережно хранит информацию о каждой продаже. Нож-отрезчик быстро отрезает ровные и аккуратные чеки.'
}

export const Viki57Plus = () =>
{
  return (
    <>
      <ItemProd data={ data } futures={ futures } />
    </>
  )
}

