import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { NaviHis } from './navihis'
import { v4 } from 'uuid'

const futures = [
  {
    id: v4(),
    link: '/sbis',
    title: 'СБИС',
    target: '_self',
  },
  {
    id: v4(),
    link: '/kkt',
    title: 'Онлайн-кассы',
    target: '_self',
  },
  // {
  //   id: v4(),
  //   link: 'http://shop.stmltd.ru/',
  //   title: 'Инетнет магазин программного обеспечения',
  //   target: '_blank'
  // },
  {
    id: v4(),
    link: '/contacts',
    title: 'Контакты',
    target: '_self',
  },
]

export const Navigation = () => {
  return (
    <>
      <Container>
        <Navbar collapseOnSelect expand="md" className="fs-4 mb-5 ">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {futures.map((future) => (
                <Nav.Item>
                  <Nav.Link
                    className="nav-custom-item"
                    href={future.link}
                    key={future.id}
                    target={future.target}
                  >
                    {future.title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  )
}
