import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '22750,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '33750,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '38750,00',
  },
]

const data = {
  img: './images/fiskalnyj-registrator-57.jpg',
  title: 'Онлайн-ККТ Viki Print 57',
  desc: 'Вы управляете небольшой торговой точкой. Онлайн-ККТ для вас скорее законодательная необходимость, чем инструмент автоматизации и учёта. Ваш выбор — онлайн-ККТ Viki Print 57. Это экономичный онлайн-ККТ для самых бережливых, он печатает на недорогой 57 мм бумаге. Viki Print подходит для магазинов, где не бывает очередей.'
}


export const Viki57 = () =>
{
  return (
    <>
      <ItemProd data={ data } futures={ futures } />
    </>
  )
}

