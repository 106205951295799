import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

export const KassaStart = () =>
{
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mb-5">
          <Col className="justify-content-center align-items-start" md={ 12 } lg={ 6 }>
            <Image
              className="me-0"
              src="./images/dreamkas-start.png"
              width="75%"
            />
          </Col>
          <Col className="d-flex flex-column justify-content-start align-items-start" md={ 12 } lg={ 6 }>
            <h4>Онлайн-касса Дримкас Старт</h4>
            <p>
              Решение для 54-ФЗ, которое состоит из бесплатной кассовой
              программы и онлайн-ККТ Вики Принт. Соответствует всем требованиям
              закона. Без абонентских платежей. Бесплатная кассовая программа
              устанавливается на ваш компьютер или ноутбук, работает со сканером
              штрихкодов и банковским терминалом. Зарегистрируйте кассу в
              Кабинете Дримкас и работайте с аналитикой продаж.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-start" md={ 12 } lg={ 6 }></Col>
          <Col className="d-flex flex-column justify-content-start align-items-start" md={ 12 } lg={ 6 }>
            <h4>Описание</h4>
            <p>
              Решение для 54-ФЗ, которое состоит из бесплатной кассовой
              программы и онлайн-ККТ Вики Принт. Бесплатная кассовая программа
              устанавливается на ваш компьютер или ноутбук, работает со сканером
              штрихкодов и банковским терминалом. Установка и использование
              программы — бесплатно, Кабинет Дримкас в комплекте без доплат.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

