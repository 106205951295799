import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/esm/Container'
import Image from 'react-bootstrap/Image'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { Footer } from '../components/footer'

export const Edo = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mb-5 mt-5">
          <Col className="d-flex flex-column justify-content-center align-items-start">
            <h5>Электронный документооборот</h5>
            <p>
              Электронная подпись и обмен документами между компаниями, внутри
              компании и c обыкновенными людьми
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column" md={12} lg={6}>
            <Image className="mb-3" src="./images/timer.png" width="50" />
            <h5>Не тратьте время на печать и доставку документов</h5>
            <p>
              Накладные, счета-фактуры создавайте как обычно или прямо в СБИС.
              Один клик - и они у клиента. Он подписывает их электронной
              подписью и отправляет вам. В итоге у вас и у него полный комплект
              подписанных юридически значимых документов. Аналогично можно
              организовать электронный документооборот не только для компаний,
              но и для сотрудников, и даже для обыкновенных людей, независимо от
              того, зарегистрированы они в СБИС или нет.
            </p>
          </Col>
          <Col className="d-flex flex-column" md={12} lg={6}>
            <Image className="mb-3" src="./images/mobilephone.png" width="50" />
            <h5>Редактируйте и подписывайте на любом устройстве</h5>
            <p>
              Встроенные в СБИС средства позволяют вам создавать и редактировать
              офисные (Word, Excel) файлы и учетные документы (накладные,
              счета-фактуры, авансовые отчеты…) на телефоне, планшете или
              компьютере. С облачной подписью вы легко заверите документы на
              любом из этих устройств.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex justify-content-center">
            <Image src="./images/tablet-coffee.jpg" className="vh-75" />
          </Col>
          <Col className="d-flex flex-column ">
            <h5>Обсуждайте и редактируйте документы с партнерами онлайн</h5>
            <p>
              Вы отправили договор клиенту, но у него возникли вопросы по тексту
              - обсудить все замечания можно в чате по документу. Здесь же можно
              организовать видеконференцию или отправить видеосообщение.
              Отредактируйте совместно текст договора в online-редакторе или в
              GoogleDocs – СБИС сохранит все версии и историю изменений
              документа, зафиксирует результаты всех обсуждений и
              видеоконференций в ленте событий электронного документооборота
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column" md={12} lg={6}>
            <Image className="mb-3" src="./images/flash-ico.png" width="50" />
            <h5>
              Подписание инструкций, приказов и других внутренних документов
            </h5>
            <p>
              Журнал учета инструктажа по технике безопасности, по пожарной
              безопасности, заявление на отпуск, приказ об изменении штатного
              расписания, оклада сотрудника - сколько бумаг мы должны оформлять
              в нашей повседневной работе. Используя электронный документооборот
              СБИС, вы можете сами выдать сотрудникам электронную подпись для
              заверения внутренних документов и навсегда избавить себя от
              беготни с оформлением и сбором подписей под этими не всегда
              нужными нам бумагами.
            </p>
          </Col>
          <Col className="d-flex flex-column " md={12} lg={6}>
            <Image className="mb-3" src="./images/warning-ico.png" width="50" />
            <h5>
              Сверьте документы с контрагентом и сведите к нулю налоговые риски
            </h5>
            <p>
              С 2015 года налоговые органы тотально сверяют книги покупок/продаж
              между контрагентами. Расхождения могут привести к проверкам. СБИС
              тщательно проверяет форму документа ваших поставщиков, реквизиты,
              подписи. Вы будете получать через электронный документооборот
              только корректную «первичку», которую налоговая при проверке точно
              не «отбракует». СБИС может сверить ваши документы с книгами
              покупок/продаж ваших контрагентов до того, как это сделает
              налоговая служба, и заранее предупредит вас о возможных
              расхождениях.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column justify-content-center">
            <Image src="./images/datacenter.jpg" className="vh-75" />
            <h5>Архив электронных и бумажных документов</h5>
            <p>
              Хранить бумажные документы неудобно – они занимают место,
              теряются, их сложно искать. Электронный архив СБИС решает все эти
              проблемы. Хранить документы в СБИС даже надежнее, чем на локальном
              компьютере. Сервера СБИС находятся в тщательно защищенном
              дата-центре. В СБИС удобно хранить и бумажные документы. Один раз
              сделайте скан и работайте дальше уже с ним (их принимает даже
              налоговая). А для связи электронного документооборота с бумажным
              отлично подойдет QR-код, который СБИС напечатает на документе.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column">
            <h5>Легкая интеграция</h5>
            <Image
              src="./images/schemaintegration.png"
              width="25%"
              height="25%"
            />
            <p>
              Если бизнес-процесс по созданию документов уже настроен в вашей
              системе, то он там и останется. Мы можем выполнить стыковку
              электронного документооборота СБИС с любой учетной системой, не
              затрагивая работу ваших сотрудников:
            </p>
            <p>
              <ul>
                <li>
                  Для самых популярных систем (1С, SAP) у нас есть готовые
                  коннекторы, позволяющие отправлять, получать документы, видеть
                  их актуальное состояние.
                </li>
                <li>
                  Если у вас специфичная система, просто выгрузите документы в
                  любом формализованном виде. СБИС возьмет на себя их
                  конвертацию в форматы ФНС, подписание и отправку. Причем будет
                  делать это автоматически и незаметно для вас.
                </li>
                <li>
                  Для интеграции с крупными корпоративными системами СБИС
                  обладает инструментами API, позволяющими решать прикладные
                  задачи любой сложности. Например, встраивать работу с
                  электронными документами в уже действующие корпоративные
                  веб-порталы.
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column justify-content-center">
            <Image src="./images/fns.jpg" />
            <h5>Госорганам тоже удобнее работать с электронными документами</h5>
            <p>
              Хранящиеся в СБИС электронные документы можно представить в суд,
              казначейство или любой другой госорган как в виде
              файлов-оригиналов, так и в виде бумажных копий со штампом. А в
              налоговую их удобно отправить через интернет: получив
              истребование, просто выберите нужные документы из списка и нажмите
              «Отправить». Далее СБИС сам сделает опись, все отправит и уведомит
              вас о приеме документов инспектором.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column justify-content-center">
            <h5>Маршруты согласования документов внутри компании</h5>
            <Image src="./images/schemadoc.png" height="25%" width="50%" />
            <p>
              направит документы сразу на него. Другой вариант - сами закрепите
              в СЭД ответственного за контрагентом или договором. Маршруты
              электронного документооборота могут быть сложными, проходящими
              цепочку согласований параллельно или последовательно в зависимости
              от разных условий (например, от суммы), проходящими через
              различных людей – просто настройте их с помощью наглядного
              редактора так, как вам требуется.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="d-flex flex-column justify-content-center">
            <Image src="./images/crypto-poster.jpg" />
            <h5>Шифруйте документы для большей безопасности</h5>
            <p>
              Все данные в электронном документообороте СБИС передаются по
              зашифрованному каналу, исключающему их перехват или подмену, но,
              если вы считаете, что этого недостаточно, можете зашифровать
              документы в адрес ваших партнеров, используя в качестве ключа
              электронную подпись или просто пароль.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}
