import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Без ФН',
    desc: '9900,00',
  },
  {
    id: v4(),
    title: 'С фн на 15 месяцев',
    desc: '22500,00',
  },
  {
    id: v4(),
    title: 'С фн на 36 месяцев',
    desc: '28000,00',
  },
]

const data = {
  img: './images/kassa-f-main.png',
  title: 'Онлайн-Касса Ф',
  desc: 'Компактная кнопочная онлайн-касса с цветным экраном. Подключается к товароучетным системам и интернет-магазину. Работает с дополнительным оборудованием и с модулем GPRS для точек, где не проведён интернет. Полностью закрывает вопрос 54-ФЗ, отправляет данные о продажах в ОФД, готова к новым изменениям в законодательстве.',
}

export const Kassaf = () => {
  return (
    <>
      <ItemProd data={data} futures={futures} />
    </>
  )
}
