import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import { BrowserRouter } from 'react-router-dom'
import { useRoutes } from './routes'


function App ()
{
  const routes = useRoutes()
  return (
    <BrowserRouter>
      <Container>{ routes }</Container>
    </BrowserRouter>
  )
}

export default App
