import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/esm/Button'
import { Footer } from '../components/footer'
import { Header } from '../components/header'
import { Navigation } from '../components/navigation'
import { v4 } from 'uuid'

const futures = [
  {
    id: v4(),
    src: './images/1.png',
    url: '/edo',
    title: 'Электронный документооборот',
    desc: 'Электронная подпись и обмен документами между компаниями, внутри компании и c обыкновенными людьми',
  },
  {
    id: v4(),
    src: './images/2.png',
    url: '/otchet',
    title: 'Отчетность через интернет',
    desc: 'Подготовка, проверка, анализ и сдача отчетности во все госорганы, сверка расчетов с бюджетом, переписка с инспекторами',
  },
  {
    id: v4(),
    src: './images/3.png',
    url: '/aboutcompany',
    title: 'Все о компанияхи владельцах',
    desc: 'Реквизиты, владельцы, финансовое состояние, стоимость бизнеса и другие самые актуальные сведения о всех компаниях в России',
  },
  {
    id: v4(),
    src: './images/4.png',
    url: '/sbis',
    title: 'Поиск и анализ закупок',
    desc: 'Актуальные данные и аналитика со всех торговых площадок, оценка шансов на победу, все для работы тендерного отдела',
  },
  {
    id: v4(),
    src: './images/5.png',
    url: '/sbis',
    title: 'Онлайн-кассы и ОФД',
    desc: 'Сервис оператора фискальных данных, модернизация и онлайн-регистрация кассовой техники, анализ продаж, мониторинг торговых точек',
  },
  {
    id: v4(),
    src: './images/6.png',
    url: '/sbis',
    title: 'Точка продаж',
    desc: 'Автоматизация магазинов и сферы услуг с поддержкой ОФД и ЕГАИС, оснащение кассовых мест «под ключ», четкая логистика и легкий бухучет',
  },
  {
    id: v4(),
    src: './images/7.png',
    url: '/sbis',
    title: 'Заказы и поставки (EDI)',
    desc: 'Обмен заказами, прайсами, документами и данными о товарах между торговыми сетями и поставщиками',
  },
  {
    id: v4(),
    src: './images/8.png',
    url: '/sbis',
    title: 'Корпоративная социальная сеть',
    desc: 'Единое пространство и удобные инструменты для совместной работы и коммуникаций',
  },
  {
    id: v4(),
    src: './images/9.png',
    url: '/sbis',
    title: 'Управление бизнес-процессами',
    desc: 'Электронные согласования, любые маршруты движения документов, настройка своих процессов по стандартам BPM без программирования',
  },
  {
    id: v4(),
    src: './images/10.png',
    url: '/sbis',
    title: 'Видеокоммуникации',
    desc: 'Видеозвонки между коллегами и партнерами, видеосовещания, вебинары, прямые звонки без регистрации, голосовые сообщения',
  },
  {
    id: v4(),
    src: './images/11.png',
    url: '/sbis',
    title: 'Управление персоналоми расчет зарплаты',
    desc: 'Учет рабочего времени, KPI, мотивация и геймификация, расчет зарплаты и учет кадров, и многое другое',
  },
  {
    id: v4(),
    src: './images/12.png',
    url: '/mercury',
    title: 'СБИС Меркурий',
    desc: 'Учет электронных ветеринарных сопроводительных документов (эВСД) на товары животного происхождения.',
  },
]

export const Sbis = () => {
  return (
    <>
      <Header />
      <Navigation />
      <Container>
        <Row className="mt-5">
          <Col>
            <h4>Сбис</h4>
            <p className="fs-6">
              Cистема обмена юридически значимыми электронными документами между
              организациями, госорганами и обыкновенными людьми. Любой документ
              или отчет, который раньше существовал на бумаге, теперь можно
              заменить электронным и, не вставая со стула, передать его, хоть в
              налоговую службу, хоть своему партнеру по бизнесу. <br />
              Подключаем к системе документооборота в Рузе, Можайске, Тучково,
              Звенигороде и других городах Московской области и России. <br />
              Консультируем по вопросам сдачи отчетности через интернет в
              налоговую, пенсионный и прочие государственные контролирующие
              органы. <br />
              Так же консультируем по вопросам связанным с государственными
              торгами, закупками и работой на госудаственных информационных
              порталах.
            </p>
          </Col>
        </Row>
        <Row className="mt-5 g-5">
          {futures.map((future) => (
            <>
              <Col className="d-flex justify-content-center">
                <Card style={{ width: '18rem', height: '20rem' }}>
                  <Card.Img variant="top" src={future.src} />
                  <Card.Body>
                    <Card.Title>{future.title}</Card.Title>
                    <Card.Text>{future.desc}</Card.Text>
                  </Card.Body>
                  <Button variant="light" type="null" href={future.url}>
                    Подробнее...
                  </Button>
                </Card>
              </Col>
            </>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  )
}
