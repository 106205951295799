import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { v4 } from 'uuid'

const futures = [
  {
    id: v4(),
    src: './images/logo-sbis.jpg',
    ref: 'https://sbis.ru/',
  },
  {
    id: v4(),
    src: './images/logo-mont.jpg',
    ref: 'https://mont.com/',
  },
  {
    id: v4(),
    src: './images/logo-kaspersky.png',
    ref: 'http://www.kaspersky.ru/',
  },
  {
    id: v4(),
    src: './images/logo-Drweb.png',
    ref: 'https://www.drweb.ru/',
  },
]

export const Partners = () =>
{
  return (
    <>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center fs-5 mb-3">
            <div>Наши партнеры:</div>
          </Col>
        </Row>
        <Row>
          { futures.map( ( future ) => (
            <>
              <Col
                className="d-flex justify-content-center align-items-center col-3"
                xs={ 12 }
                lg={ 3 }
              >
                <a href={ future.ref } target="_blank" rel="noreferrer">
                  <Image className="me-0 mb-1" src={ future.src } width="150" />
                </a>
              </Col>
            </>
          ) ) }
        </Row>
      </Container>
    </>
  )
}

